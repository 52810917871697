import request from '@http';

// 2022/05/23 王江毅 供应商列表
export function getSupplierList(params) {
    return request({
        method: 'GET',
        url: '/api/supplier/list',
        params
    })
}

// 2022/05/23 王江毅 添加供应商
export function pubCompany(data) {
    return request({
        method: 'PUT',
        url: '/api/supplier/pubCompany',
        data
    })
}

// 2022/05/23 王江毅 供应商列表不分页
export function getSupplierListAll() {
    return request({
        method: 'GET',
        url: 'api/supplier/listAll'
    })
}