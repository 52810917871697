<template>
    <r-e-dialog title="资产入库" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="800px"
                top="5vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" label-position="top" size="small" :model="formPublish"
                 :rules="rules">
            <div class="title">基本信息</div>
            <div style="margin: 10px 0;border-bottom: 1px solid #F1F1F3;"></div>
            <div class="flex justify-around">
                <el-form-item label="资产编码" prop="assetCode">
                    <el-input v-model="formPublish.assetCode" disabled style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="资产类别" prop="assetType">
                    <el-select v-model="formPublish.assetType" filterable style="width: 200px;" placeholder="请选择">
                        <el-option v-for="(item,index) in assetTypeList" :key="index" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="资产名称" prop="assetName">
                    <el-input v-model="formPublish.assetName" style="width: 200px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-around">
                <el-form-item label="规格型号" prop="model">
                    <el-input v-model="formPublish.model" style="width: 200px;"/>
                </el-form-item>
                <!--<el-form-item label="SN号" prop="assetSn">
                    <el-input v-model="formPublish.assetSn" style="width: 200px;"/>
                </el-form-item>-->
                <el-form-item label="合同日期" prop="contractDate">
                    <el-date-picker v-model="formPublish.contractDate" style="width: 200px;" type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd" placeholder="选择日期"/>
                </el-form-item>

                <el-form-item label="供应商" prop="supplierUuid">
                    <el-select v-model="formPublish.supplierUuid" filterable style="width: 200px;" placeholder="请选择">
                        <el-option v-for="(item,index) in supplierList" :key="index" :label="item.companyName"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex justify-around">
                <el-form-item label="采购数量" prop="number">
                    <el-input v-model="formPublish.number" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="单位" prop="unit">
                    <el-input v-model="formPublish.unit" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="产品单价" prop="price">
                    <el-input v-model="formPublish.price" style="width: 200px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-around">
                <el-form-item label="采购申请&采购合同号" prop="purchaseContractNo">
                    <el-input v-model="formPublish.purchaseContractNo" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="购入日期" prop="intakeDate">
                    <el-date-picker v-model="formPublish.intakeDate" style="width: 200px;" type="date"
                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"/>
                </el-form-item>
<!--                <el-form-item label="所属公司" prop="company">
                    <el-input v-model="formPublish.company" style="width: 200px;"/>
                </el-form-item>-->
                <el-form-item label="合同批次" prop="contractBatch">
                    <el-input v-model="formPublish.contractBatch" style="width: 200px;"/>
                </el-form-item>
            </div>
            <div class="flex justify-around">
                <div style="width: 200px;"/>
<!--                <el-form-item label="存放库房" prop="companyStockUuid">
                    <el-select v-model="formPublish.companyStockUuid" filterable style="width: 200px;"
                               placeholder="请选择">
                        <el-option v-for="(item,index) in stockList" :key="index" :label="item.stockName"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>-->
                <!--<el-form-item label="入库员" prop="amdinUuid">
                    <el-select v-loading="loading" v-model="formPublish.amdinUuid" filterable style="width: 200px;" placeholder="请选择">
                        <el-option v-for="(item,index) in stockerList" :key="index" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>-->
<!--                <el-form-item label="入库日期" prop="storageDate">
                    <el-date-picker v-model="formPublish.storageDate" style="width: 200px;" type="date"
                                    format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"/>
                </el-form-item>-->
                <el-form-item label="设备类型" prop="assetsTypeCode">
                    <el-select v-model="formPublish.assetsTypeCode" filterable style="width: 200px;" placeholder="请选择">
                        <el-option v-for="(item,index) in assetsTypeCodeist" :key="index" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否分配房间" prop="fenPeiApartment">
                    <div style="width: 200px;">
                        <el-radio-group v-model="formPublish.fenPeiApartment">
                            <el-radio :label="false">否</el-radio>
                            <el-radio :label="true">是</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </div>
            <div class="flex justify-around" v-if="formPublish.fenPeiApartment">
                <el-form-item label="小区">
                    <el-select v-model="communityUuid" filterable style="width: 200px;" placeholder="请输入关键词">
                        <el-option v-for="item in communityList" :key="item.uuid" :label="item.name"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间">
                    <el-select v-model="formPublish.apartmentUuids" filterable clearable multiple collapse-tags
                               style="width: 200px;" placeholder="请输入关键词" v-loading="apartmentLoading">
                        <el-option v-for="item in apartmentUuidsList" :key="item.uuid" :label="item.name"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <div style="width: 200px;"/>
            </div>

            <div class="title">其他信息</div>
            <div style="margin: 10px 0;border-bottom: 1px solid #F1F1F3;"></div>
            <div class="flex justify-around">
                <el-form-item label="照片附件" class="samplePhotos">
                    <upload-pictureCard :uuidList="photos" :limit="24" @on-success="handleSuccess"
                                        @on-remove="handleRemove"/>
                </el-form-item>
                <div style="width: 320px;"></div>
            </div>
            <div class="flex justify-around">
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" v-model="formPublish.remark" :rows="3" placeholder="请输入内容"
                              style="width: 700px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
import {publish} from "@/api/assets";
import {MessageSuccess} from "@custom/message";
import {getStockListAll, getStockerListBystocksUuid} from '@/api/warehouse';
import {getSupplierListAll} from "@/api/supplier";
import {getCommunityListApi} from "@/api/community-management"
import {getApartmentsByCommunity} from "@/api/house-resource";
import { timeFormat } from "@custom/index";
export default {
    name: "dialog-add-warehousing",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                assetCode: "",
                assetType: "",
                assetName: "",
                model: "",
                // assetSn: "",
                storageDate: "",
                number: "",
                unit: "",
                price: "",
                purchaseContractNo: "",
                intakeDate: "",
                company: "富航美寓",
                // companyStockUuid: "",
                // amdinUuid: "",
                supplierUuid: "",
                remark: "",

                contractDate: null,
                contractBatch: null,

                assetsTypeCode: null,

                fenPeiApartment: false,
                apartmentUuids: []
            },
            rules: {
                assetCode: [{required: true, message: '请输入资产编码', trigger: 'blur'}],
                assetType: [{required: true, message: '请选择资产类别', trigger: 'change'}],
                assetName: [{required: true, message: '请输入资产名称', trigger: 'blur'}],
                model: [{required: true, message: '请选择规格型号', trigger: 'blur'}],
                // assetSn: [{required: true, message: '请输入SN号', trigger: 'blur'}],
                storageDate: [{required: true, message: '请输入入库日期', trigger: 'blur'}],
                number: [{required: true, message: '请输入采购数量', trigger: 'blur'}],
                unit: [{required: true, message: '请输入单位', trigger: 'blur'}],
                price: [{required: true, message: '请输入产品单价', trigger: 'blur'}],
                purchaseContractNo: [],
                intakeDate: [{required: true, message: '请选择购入日期', trigger: 'change'}],
                company: [{required: true, message: '请输入所属公司', trigger: 'blur'}],
                // companyStockUuid: [{required: true, message: '请输入存放库房', trigger: 'blur'}],
                // amdinUuid: [{required: true, message: '请选择入库员', trigger: 'change'}],
                supplierUuid: [{required: true, message: '请选择供应商', trigger: 'change'}],
                remark: [],
                assetsTypeCode: [{required: true, message: '请选择设备类型', trigger: 'change'}],
                fenPeiApartment: [{required: true, message: '请选择是否分配房间', trigger: 'change'}],
                contractDate: [],
                contractBatch: [],
            },
            photos: [],
            assetTypeList: [],
            stockList: [],
            loading: false,
            stockerList: [],
            supplierList: [],
            assetsTypeCodeist: [],
            communityUuid: null,
            communityList: [],
            apartmentUuidsList: [],
            apartmentLoading: false,

            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {uploadPictureCard},
    methods: {
        async openDialog() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            let {list: communityList} = await getCommunityListApi();
            communityList = communityList.filter(({apartmentsCount}) => apartmentsCount > 0);
            communityList = communityList.map(({name, uuid}) => { return {name, uuid} });
            this.communityList = communityList;
            that.formPublish.storageDate = timeFormat(new Date());
            loading.close();
            that.dialogVisible = true;
        },
        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let {
                        assetCode, assetType, assetName, model, storageDate, number, unit, price, purchaseContractNo,
                        intakeDate, company, remark, supplierUuid, assetsTypeCode, fenPeiApartment, //companyStockUuid,
                        apartmentUuids, contractDate, contractBatch,
                    } = that.formPublish;
                    price = parseInt((price * 100).toFixed(0));
                    let photos = that.photos.join(";");
                    let data = {
                        assetCode, assetType, assetName, model, storageDate, number, unit, price, purchaseContractNo,
                        intakeDate, company, remark, photos, supplierUuid, assetsTypeCode, //companyStockUuid,
                        fenPeiApartment, apartmentUuids, contractDate, contractBatch,
                    }
                    publish(data).then(res => {
                        MessageSuccess('添加入库成功');
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.$refs["formPublish"].resetFields();
            this.$emit("handleSearch", false);
            this.formPublish.storageDate = null;
            this.dialogVisible = false;
        },

        handleSuccess({fileList}) {
            this.photos = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        handleRemove({fileList}) {
            this.photos = fileList.map(item => {
                let {name, response} = item;
                let resOk = response && typeof (response) !== undefined;
                if (resOk) {
                    let {returnObject: {info: {uuid}}} = response;
                    return uuid;
                } else return name;
            });
        },

        getStockerListBystocksUuid(uuid) {
            let that = this;
            that.loading = true;
            getStockerListBystocksUuid(uuid).then(res => {
                that.loading = false;
                let {list} = res;
                that.stockerList = list;
            });
        },
        async renewalByName(communityUuid) {
            this.apartmentLoading = true;
            let {list} = await getApartmentsByCommunity(communityUuid);
            this.apartmentUuidsList = list;
            this.apartmentLoading = false;
        },
    },
    async mounted() {
        this.assetTypeList = await this.$store.dispatch('app/getDictionaryByCodeActions', 200000);
        this.assetsTypeCodeist = await this.$store.dispatch('app/getDictionaryByCodeActions', 200300);
        let stockListInfo = await getStockListAll();
        const {list: stockList} = stockListInfo;
        this.stockList = stockList;
        let {list: supplierList} = await getSupplierListAll();
        this.supplierList = supplierList;
    },
    watch: {
        'formPublish.assetType'(value) {
            this.formPublish.assetCode = value;
        },
        'formPublish.companyStockUuid'(value) {
            this.formPublish.amdinUuid = '';
            if (value) this.getStockerListBystocksUuid(value);
        },
        communityUuid(value) {
            if (value) {
                this.formPublish.apartmentUuids = [];
                this.apartmentUuidsList = [];
                this.renewalByName(value);
            } else this.apartmentUuidsList = [];
        },
        'formPublish.fenPeiApartment'(value) {
            this.formPublish.apartmentUuids = [];
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
    line-height: 0 !important;
    padding: 0 0 5px 0 !important;
}

.title {
    position: relative;
    padding-left: 10px;
    font-size: 18px;

    &::before {
        width: 5px;
        height: 100%;
        content: '';
        background-color: #F5A623;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>